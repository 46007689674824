import clsx from "clsx";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  title?: string;
  id?: string;
  isHighlightedOnHover?: boolean;
  paddingClassName?: string;
};

const Tile = ({
  id,
  title,
  children,
  className,
  isHighlightedOnHover,
  paddingClassName,
}: Props) => (
  <div
    id={id}
    className={clsx(
      "text-base rounded-xl bg-content-secondary text-foreground-secondary",
      className,
      paddingClassName ?? "p-6",
      isHighlightedOnHover && "hover:bg-fill-secondary active:bg-fill-primary"
    )}
  >
    {title && (
      <p className="mb-1 text-xl font-bold text-foreground-primary">{title}</p>
    )}
    {children}
  </div>
);

export default Tile;
