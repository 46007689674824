import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import useSearchState from "../hooks/use-search-state";
import { MoneyKitMode } from "../types";

type Props = {
  children: ReactNode;
};

const moneykitModes = Object.values(MoneyKitMode);
const defaultMode = MoneyKitMode.live;

const MoneyKitModeContext = createContext<
  | {
      mode: MoneyKitMode;
      setMode: Dispatch<SetStateAction<MoneyKitMode>>;
    }
  | undefined
>(undefined);

/**
 * MoneyKitModeProvider makes the currently active MoneyKit environment available to its children
 * and handles synchronising that selected value with the current URL's query parameters.
 */
export const MoneyKitModeProvider = ({ children }: Props) => {
  const [queryMode, setQueryMode] = useSearchState(
    "mode",
    defaultMode.toString()
  );

  const [mode, setMode] = useState(() => {
    const queryEnvIndex = moneykitModes.findIndex((mode) => mode === queryMode);
    return moneykitModes[queryEnvIndex];
  });

  useEffect(() => {
    setQueryMode(mode);
  }, [mode, setQueryMode]);

  return (
    <MoneyKitModeContext.Provider value={{ mode, setMode: setMode }}>
      {children}
    </MoneyKitModeContext.Provider>
  );
};

/**
 * Provides the currently selected MoneyKit environment value as well as a setter function to update it.
 */
export const useMoneyKitMode = () => {
  const context = useContext(MoneyKitModeContext);
  if (context === undefined) {
    throw new Error(
      "useMoneyKitEnv must be used within a MoneyKitModeProvider"
    );
  }

  return context;
};
