import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useSearchState = (name: string, defaultValue?: string) => {
  /**
   * Synchronizes a given URL query parameter with the app state.
   */
  const [searchParams, setSearchParams] = useSearchParams();
  const queryValue = searchParams.get(name);

  const [value, setValue] = useState(queryValue ?? defaultValue ?? null);

  // Keep the query parameter in sync with the app state
  useEffect(() => {
    if (queryValue !== value) {
      if (value === null || value === "") {
        searchParams.delete(name);
      } else {
        searchParams.set(name, value);
      }

      setSearchParams(searchParams, { replace: true });
    }
  }, [value, queryValue, name, searchParams, setSearchParams]);

  return [value, setValue] as const;
};

export default useSearchState;
