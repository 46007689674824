import { useEffect, useState } from "react";
import { UseFormRegister, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";

import {
  useCreateAppClientMutation,
  usePartnerScopesQuery,
} from "../../api/moneykit";
import {
  AppClientWithSecret,
  CreateAppClientRequest,
  PartnerScopeAvailableType,
} from "../../api/types";
import CopyButton from "../../components/copy-button";
import Button from "../../components/forms/button";
import Input from "../../components/forms/input";
import Tile from "../../components/tile";
import TitleBar from "../../components/title-bar";
import IconClose from "../../images/close";

const AppClientCreate = () => {
  const { partnerID = "", appID = "" } = useParams();
  const { register, handleSubmit } = useForm<CreateAppClientRequest>();
  const create = useCreateAppClientMutation(partnerID, appID);
  const [clientData, setClientData] = useState<AppClientWithSecret>(null);

  const onSubmit = handleSubmit((appClient: CreateAppClientRequest) => {
    // if scopes is undefined or null, initialize as empty object
    appClient.scopes = appClient.scopes || {};

    const scopes: string[] = Object.keys(appClient.scopes).filter(
      // @ts-expect-error — key is an unknown string but TS is throwing a fit
      (key: string) => appClient.scopes[key as unknown as string]
    );

    create.mutate(
      {
        ...appClient,
        scopes,
      },
      {
        onSuccess: (created) => {
          setClientData(created);
        },
        onError: (error) => {
          console.error(error);
          alert(
            "Failed to create app client. Please try again or check the console for more details."
          );
        },
      }
    );
  });

  useEffect(() => {
    // scroll to top on mount
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TitleBar
        title={
          clientData
            ? clientData.api_client.client_name
            : "Create a New App Client"
        }
      >
        <Link
          to={`/partners/${partnerID}`}
          className="relative p-3 ml-auto -mr-3 cursor-pointer active:opacity-50"
        >
          <IconClose className="block fill-foreground-secondary" />
        </Link>
      </TitleBar>

      <div className="w-full px-10 pb-10 text-foreground-primary">
        {clientData ? (
          <Success clientData={clientData} />
        ) : (
          <Form onSubmit={onSubmit} register={register} />
        )}
      </div>
    </>
  );
};

export default AppClientCreate;

const Form = ({
  onSubmit,
  register,
}: {
  onSubmit: () => void;
  register: UseFormRegister<CreateAppClientRequest>;
}) => {
  const { data: scopes, isLoading } = usePartnerScopesQuery();
  const [defaultScopes, setDefaultScopes] = useState<string[]>([]);
  const [allScopes, setAllScopes] = useState<PartnerScopeAvailableType[]>([]);

  useEffect(() => {
    if (scopes && !isLoading) {
      setDefaultScopes(scopes.default);
      setAllScopes(scopes.available);
    }
  }, [scopes, isLoading]);

  return (
    <form onSubmit={onSubmit} className="grid gap-6">
      <Input
        label="Client Name"
        type="text"
        className="max-w-lg"
        required
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        {...register("client_name")}
      />
      <fieldset>
        <legend className="text-base font-bold uppercase text-foreground-secondary">
          Scopes
        </legend>
        {isLoading ? (
          <div className="font-bold opacity-25 mt-6 min-h-[900px]">
            Loading...
          </div>
        ) : (
          <div>
            {allScopes.map((item) => {
              const isDefaultScope = defaultScopes.includes(item.scope);

              return (
                <div
                  className="relative flex items-start py-3 transition-opacity active:opacity-75"
                  key={item.scope}
                >
                  <div className="flex items-center h-6">
                    <input
                      id={item.scope}
                      type="checkbox"
                      className="w-6 h-6 border-none rounded-md cursor-pointer bg-separator-primary text-accent focus:ring-background-primary"
                      defaultChecked={isDefaultScope}
                      // @ts-expect-error — TS wants a controlled input but it's less of a headache
                      // to use react-hook-form here and handle the data on submit
                      {...register(`scopes.${item.scope}`)}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor={item.scope}
                      className="font-mono font-bold cursor-pointer text-foreground-primary"
                    >
                      <span className="block">{item.scope}</span>
                      <span className="block font-sans font-normal text-foreground-secondary">
                        {item.description}
                      </span>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </fieldset>
      <div className="mt-5">
        <Button>Create App Client</Button>
      </div>
    </form>
  );
};

const Success = ({ clientData }: { clientData: AppClientWithSecret }) => {
  if (!clientData) return null;

  return (
    <div className="grid gap-6">
      <Tile title="Client ID">
        <div className="flex items-center h-8 font-mono text-foreground-primary">
          <p>{clientData.api_client.client_id}</p>
          <CopyButton content={clientData.api_client.client_id} />
        </div>
      </Tile>
      <Tile title="Client Secret">
        <div className="flex items-center h-8 font-mono text-foreground-primary">
          <p>{clientData.client_secret}</p>
          <CopyButton content={clientData.client_secret} />
        </div>
        <div className="mt-5">
          <p className="text-base font-medium text-state-warning">
            Make sure to copy your Client Secret before closing this page.
            <br />
            It will NOT be shown again.
          </p>
        </div>
      </Tile>

      <Tile title="Scopes">
        {clientData.api_client.scopes.map((item: PartnerScopeAvailableType) => {
          return (
            <p
              className="pt-3 pb-3 font-mono font-bold border-b cursor-pointer text-foreground-primary last:pb-0 border-separator-primary last:border-none"
              key={item.scope}
            >
              <span className="block">{item.scope}</span>
              <span className="block font-sans font-normal text-foreground-secondary">
                {item.description}
              </span>
            </p>
          );
        })}
      </Tile>
    </div>
  );
};
