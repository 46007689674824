import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useMemo } from "react";
import ReactSelect from "react-select";
import type { GroupBase, Props } from "react-select";

import { classNames } from "@mk/util-css";

// Emotion is the styling library used by React Select. In order to override those default styles,
// we need to ensure the Emotion styles are loaded in before Tailwind's styles, giving the latter precedence.
// https://react-select.com/styles#the-classnames-prop
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const titleElement = document.querySelector("title");

  const cache = useMemo(() => {
    return createCache({
      key: "with-tailwind",
      insertionPoint: titleElement || undefined,
    });
  }, [titleElement]);

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export type SelectOption<Value> = {
  value: Value;
  label: string;
};

// Select is a thin wrapper around the third party react-select component.
// For details of available props and customisation options see https://react-select.com/props.
export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  return (
    <EmotionCacheProvider>
      <ReactSelect
        {...props}
        isSearchable={false}
        styles={{
          control: (base) => ({
            border: 0,
            boxShadow: "none",
          }),
          menu: () => ({}),
          menuList: () => ({}),
          option: () => ({}),
          singleValue: () => ({}),
          placeholder: () => ({}),
          dropdownIndicator: () => ({}),
          valueContainer: () => ({}),
        }}
        classNames={{
          clearIndicator: () => "text-foreground-primary",
          container: (state) =>
            classNames("group", state.isFocused ? "menu-open" : "menu-closed"),
          control: () =>
            classNames(
              "flex flex-row items-center justify-between rounded-xl bg-fill-tertiary hover:bg-fill-secondary active:bg-fill-primary cursor-pointer font-bold text-sm pl-4 pr-3 h-10 transition duration-150 ease-out"
            ),
          indicatorSeparator: () => "hidden",
          indicatorsContainer: () => "text-foreground-primary p-0",
          dropdownIndicator: () =>
            "text-foreground-primary flex items-center justify-center hover:text-foreground-primary p-0",
          placeholder: () => "text-foreground-primary text-sm px-0 mx-0",
          singleValue: () =>
            "flex-1 text-foreground-primary text-sm pl-0 pr-4 mx-0",
          valueContainer: () => "flex flex-row items-center justify-center p-0",
          menu: () =>
            classNames(
              "z-[50] min-w-[110px] absolute left-0 top-[calc(100%+4px)] bg-background-primary dark:bg-content-secondary rounded-xl shadow-sm border border-black/10 dark:border-white/10 transition duration-100 ease-out"
            ),
          menuList: () => "border-none p-1 overflow-hidden",
          option: (state) =>
            classNames(
              "user-select-none px-4 py-2.5 mb-1 last:mb-0 text-foreground-primaryy text-sm font-bold cursor-pointer rounded-lg transition duration-100 ease-out",
              state.isSelected
                ? "bg-foreground-primary text-background-primary"
                : "text-foreground-primary hover:bg-fill-tertiary"
            ),
        }}
      />
    </EmotionCacheProvider>
  );
};

export default Select;
