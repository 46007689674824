import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAccessToken } from "../../api/auth";
import { getInstitution, getLink, getPartner } from "../../api/moneykit";
import { useKeyboardShortcut } from "../../hooks/use-keyboard-shortcut";
import { useMoneyKitMode } from "../../providers/moneykit-mode";
import { prettyProviderName } from "../../util/providers";

type QuickOpenResultType = "link" | "institution" | "partner";

type QuickOpenResult = {
  type: QuickOpenResultType;
  imageUrl: string | null;
  title: string;
  detail?: string;
  href: string;
};

export const QuickOpen = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const accessToken = useAccessToken();
  const { mode } = useMoneyKitMode();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState("");
  const [focus, setFocus] = useState<QuickOpenResultType | null>(null);
  const [loading, setLoading] = useState(false);

  const [linkResult, setLinkResult] = useState<QuickOpenResult | null>(null);
  const [institutionResult, setInstitutionResult] =
    useState<QuickOpenResult | null>(null);
  const [partnerResult, setPartnerResult] = useState<QuickOpenResult | null>(
    null
  );

  useEffect(() => {
    if (open) {
      setQuery("");
      reset();
      searchInputRef.current?.focus();
    } else {
      searchInputRef.current?.blur();
    }
  }, [open]);

  const handleEscape = () => {
    onClose();
  };

  const handleEnter = () => {
    if (focus === "link" && linkResult) {
      openHref(linkResult.href);
    } else if (focus === "institution" && institutionResult) {
      openHref(institutionResult.href);
    } else if (focus === "partner" && partnerResult) {
      openHref(partnerResult.href);
    }
  };

  const openHref = (href: string) => {
    navigate(href);
    onClose();
  };

  const reset = () => {
    setFocus(null);
    setLinkResult(null);
    setInstitutionResult(null);
    setPartnerResult(null);
  };

  let currentRequest: NodeJS.Timeout | null;

  const handleInputChange = async (newValue: string) => {
    if (currentRequest) {
      clearTimeout(currentRequest);
    }

    setQuery(newValue.trim());

    let searchingLinks = newValue.startsWith("mk_");
    let searchingInstitutions = newValue.length > 0;
    let searchingPartners = newValue.length > 20; // artificial amount

    let newLinkResult: QuickOpenResult | null;
    let newInstitutionResult: QuickOpenResult | null;
    let newPartnerResult: QuickOpenResult | null;

    if (searchingLinks || searchingInstitutions || searchingPartners) {
      setLoading(true);
    } else {
      reset();
      return;
    }

    const onCompletion = () => {
      if (!searchingLinks && !searchingInstitutions && !searchingPartners) {
        setLoading(false);

        setLinkResult(newLinkResult);
        setInstitutionResult(newInstitutionResult);
        setPartnerResult(newPartnerResult);

        if (newLinkResult) {
          setFocus("link");
        } else if (newInstitutionResult) {
          setFocus("institution");
        } else if (newPartnerResult) {
          setFocus("partner");
        }
      }
    };

    // Add a 300ms delay (debounce)
    await new Promise((resolve) => (currentRequest = setTimeout(resolve, 200)));

    if (newValue !== searchInputRef.current?.value) {
      return;
    }

    // Find Link
    if (searchingLinks) {
      try {
        const appLink = await getLink(newValue, accessToken);
        newLinkResult = {
          type: "link",
          imageUrl: appLink.institution.avatar_image_url,
          title: appLink.institution.name,
          detail: prettyProviderName(appLink.provider),
          href: `/links/${newValue}?mode=${mode}`,
        };
      } catch (e) {
        // do nothing
      } finally {
        searchingLinks = false;
        onCompletion();
      }
    }

    // Find Institution
    if (searchingInstitutions) {
      try {
        const institution = await getInstitution(newValue, accessToken);
        newInstitutionResult = {
          type: "institution",
          imageUrl: institution.avatar,
          title: institution.name,
          href: `/institutions/${institution.external_id}?mode=${mode}`,
        };
      } catch (e) {
        // do nothing
      } finally {
        searchingInstitutions = false;
        onCompletion();
      }
    }

    // Find Partner
    if (searchingPartners) {
      try {
        const partner = await getPartner(newValue, accessToken);
        newPartnerResult = {
          type: "partner",
          imageUrl: null,
          title: partner.name,
          href: `/partners/${partner.id}?mode=${mode}`,
        };
      } catch (e) {
        // do nothing
      } finally {
        searchingPartners = false;
        onCompletion();
      }
    }
  };

  useKeyboardShortcut(["escape"], handleEscape);

  const noResults = !linkResult && !institutionResult && !partnerResult;

  return (
    <div
      className={clsx(
        "transition duration-100 ease-out fixed top-0 left-0 z-20 flex justify-center items-start w-full h-full px-16 pt-24",
        open ? "opacity-100" : "opacity-0 pointer-events-none"
      )}
    >
      <button
        className="absolute top-0 left-0 w-full h-full bg-black/60"
        type="button"
        onClick={() => onClose()}
      />
      <div
        className={clsx(
          "shadow-xl flex flex-col relative bg-content-primary w-[540px] rounded-xl overflow-hidden max-w-full transition duration-150 ease-out",
          open
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-[0.97] translate-y-4"
        )}
      >
        <div className="relative w-full h-20">
          <div className="absolute -translate-y-1/2 left-6 top-1/2 text-foreground-secondary">
            <SearchIcon />
          </div>
          <input
            autoComplete="off"
            ref={searchInputRef}
            value={query}
            className="caret-accent w-full h-full pr-28 text-2xl font-semibold bg-transparent border-none focus:border-none ring-0 focus:ring-0 outline-none focus:outline-none appearance-none pl-[72px] text-foreground-primary placeholder:text-foreground-tertiary"
            placeholder="Open Quickly"
            onChange={(e) => handleInputChange(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleEnter()}
          />
          <p
            className={clsx(
              "absolute px-2 py-1 text-base font-semibold -translate-y-1/2 border rounded-md pointer-events-none right-6 top-1/2 text-foreground-secondary border-separator-primary",
              noResults && "opacity-50"
            )}
          >
            Open <span className="inline-block translate-y-0.5">&#x21a9;</span>
          </p>
        </div>

        <BookmarksBar />

        {!loading && noResults && (
          <div className="px-6 h-[104px] leading-[104px] text-base text-center text-foreground-secondary font-medium">
            {query.length === 0
              ? "Search by Link, Institution, or Partner ID"
              : "No Results Found"}
          </div>
        )}

        {(loading || !noResults) && (
          <div className="flex flex-col gap-1 p-4">
            {loading ? (
              <QuickOpenResultLoadingRow />
            ) : (
              <>
                {linkResult && (
                  <QuickOpenResultRow
                    result={linkResult}
                    focus={true}
                    onOpen={(href) => openHref(href)}
                  />
                )}

                {institutionResult && (
                  <QuickOpenResultRow
                    result={institutionResult}
                    focus={!linkResult}
                    onOpen={(href) => openHref(href)}
                  />
                )}

                {partnerResult && (
                  <QuickOpenResultRow
                    result={partnerResult}
                    focus={!linkResult && !institutionResult}
                    onOpen={(href) => openHref(href)}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const QuickOpenResultLoadingRow = () => {
  return (
    <div
      className={clsx(
        "relative flex flex-row items-center w-full gap-4 p-4 rounded-lg"
      )}
    >
      <div className="flex-none w-10 h-10 rounded-full bg-fill-tertiary animate-pulse"></div>
      <span className="relative flex-1 h-5">
        <span className="block w-1/2 h-full rounded-md bg-fill-tertiary animate-pulse"></span>
      </span>
      <span className="relative flex-none h-5">
        <span className="block w-20 h-full rounded-md bg-fill-tertiary animate-pulse"></span>
      </span>
    </div>
  );
};

const QuickOpenResultRow = ({
  result,
  focus,
  onOpen,
}: {
  result: QuickOpenResult;
  focus: boolean;
  onOpen: (href: string) => void;
}) => {
  return (
    <button
      type="button"
      onClick={() => onOpen(result.href)}
      className={clsx(
        "flex flex-row items-center w-full gap-4 p-4 rounded-lg hover:bg-fill-tertiary transition duration-100 ease-out",
        focus ? "opacity-100 bg-fill-tertiary" : "opacity-90"
      )}
    >
      <div className="relative w-10 h-10 overflow-hidden rounded-full">
        {result.imageUrl ? (
          <img src={result.imageUrl} width={40} height={40} />
        ) : (
          <div className="flex items-center justify-center w-full h-full text-lg font-bold text-center text-white bg-accent">
            {Array.from(result.title)[0].toUpperCase()}
          </div>
        )}
      </div>
      <span className="flex flex-row items-center justify-start flex-1 text-lg text-foreground-primary">
        <span className="flex-1 font-semibold text-left">{result.title}</span>
        {result.detail && (
          <span className="ml-2 text-foreground-secondary font-regular">
            — {result.detail}
          </span>
        )}
      </span>
      <span className="flex-none text-lg font-regular text-foreground-secondary">
        {result.type}
      </span>
    </button>
  );
};

const SearchIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8225 11.0494C16.8225 13.9534 14.3616 16.5031 11.0812 16.5031C7.8007 16.5031 5.33984 13.9534 5.33984 11.0494C5.33984 8.14534 7.8007 5.5957 11.0812 5.5957C14.3616 5.5957 16.8225 8.14534 16.8225 11.0494ZM16.2889 18.7452C14.7938 19.6985 13.0042 20.2531 11.0812 20.2531C5.83925 20.2531 1.58984 16.1324 1.58984 11.0494C1.58984 5.96633 5.83925 1.8457 11.0812 1.8457C16.3231 1.8457 20.5725 5.96633 20.5725 11.0494C20.5725 12.9324 19.9893 14.6833 18.9886 16.1416L21.8633 19.0163C22.5952 19.7482 22.5766 20.9168 21.8219 21.6266C21.0671 22.3363 19.862 22.3183 19.1301 21.5864L16.2889 18.7452Z"
      fill="currentColor"
    />
  </svg>
);

const BookmarksBar = () => {
  return (
    <div className="flex flex-row items-center justify-center flex-none w-full px-2 py-1 text-xs font-medium transition duration-200 ease-out bg-separator-tertiary">
      <BookmarkLink href="https://docs.moneykit.com" title="Docs" />
      <BookmarkSeparator />
      <BookmarkLink href="https://control.moneykit.com" title="Control" />
      <BookmarkLink
        href="https://control.stage.moneykit.com"
        title="Control (Staging)"
      />
      <BookmarkSeparator />
      <BookmarkLink href="https://demo.moneykit.com" title="Playground" />
      <BookmarkLink
        href="https://demo.stage.ue2.moneykit.com"
        title="Playground (Staging)"
      />
    </div>
  );
};

const BookmarkSeparator = () => (
  <div className="flex-none block w-px h-2 mx-2 bg-separator-primary"></div>
);

const BookmarkLink = ({ href, title }: { href: string; title: string }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="px-2 py-1 transition duration-200 ease-out rounded-md hover:bg-fill-tertiary text-foreground-secondary hover:text-foreground-primary"
  >
    {title}
  </a>
);
