import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ComponentType, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import Auth0Provider from "./auth0/provider";
import { MoneyKitModeProvider } from "./providers/moneykit-mode";
import Layout from "./routes/layout";
import Login from "./routes/login";
import AppClientCreate from "./routes/partners/create-api-client";

// Lazily-loaded route components will be split into their own JS bundles in production and loaded on demand.
const Coverage = lazy(() => import("./routes/coverage"));
const Conversion = lazy(() => import("./routes/conversion"));
const Status = lazy(() => import("./routes/status"));
const Links = lazy(() => import("./routes/links"));
const LinkDetail = lazy(() => import("./routes/links/detail"));
const Partners = lazy(() => import("./routes/partners"));
const PartnerDetail = lazy(() => import("./routes/partners/detail"));
const PartnerCreate = lazy(() => import("./routes/partners/create"));
const PartnerAppCreate = lazy(() => import("./routes/partners/create-app"));
const Apps = lazy(() => import("./routes/apps"));
const AppDetail = lazy(() => import("./routes/apps/detail"));
const Institutions = lazy(() => import("./routes/institutions"));
const InstitutionDetail = lazy(() => import("./routes/institutions/detail"));
const DevTools = lazy(() => import("./routes/dev-tools"));
const Editor = lazy(() => import("./routes/editor"));
const InsPlayground = lazy(() => import("./routes/ins-playground"));
const TxnPlayground = lazy(() => import("./routes/txn-playground"));
const ColorPlayground = lazy(() => import("./routes/color-playground"));

// Initialize react-query client.
const queryClient = new QueryClient();

// Route components wrapped with ProtectedRoute cannot be accessed unless an authenticated user is logged in.
// If not, the user is redirected out to login, then redirected back to their intended destination upon success.
const ProtectedRoute = ({
  component,
  ...args
}: {
  component: ComponentType;
}) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const App = () => {
  return (
    <BrowserRouter>
      <Auth0Provider>
        <QueryClientProvider client={queryClient}>
          <MoneyKitModeProvider>
            {/* Uncomment this and the ReactQueryDevtools import above to enable react-query dev tools (local debugging only). */}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <SentryRoutes>
              <Route path="/login" element={<Login />} />

              <Route
                path="coverage"
                element={<ProtectedRoute component={Coverage} />}
              />

              <Route
                path="editor"
                element={<ProtectedRoute component={Editor} />}
              />

              <Route
                path="ins-playground"
                element={<ProtectedRoute component={InsPlayground} />}
              />

              <Route path="txn-playground" element={<TxnPlayground />} />

              <Route path="color-playground" element={<ColorPlayground />} />

              <Route path="/" element={<Layout />}>
                <Route
                  path=""
                  element={<ProtectedRoute component={Conversion} />}
                />
                <Route
                  path="status"
                  element={<ProtectedRoute component={Status} />}
                />
                <Route
                  path="links"
                  element={<ProtectedRoute component={Links} />}
                />
                <Route
                  path="links/:appLinkID"
                  element={<ProtectedRoute component={LinkDetail} />}
                />

                <Route
                  path="institutions"
                  element={<ProtectedRoute component={Institutions} />}
                />

                <Route
                  path="institutions/:institutionID"
                  element={<ProtectedRoute component={InstitutionDetail} />}
                />

                <Route
                  path="partners"
                  element={<ProtectedRoute component={Partners} />}
                />

                <Route
                  path="partners/create"
                  element={<ProtectedRoute component={PartnerCreate} />}
                />

                <Route
                  path="partners/:partnerID"
                  element={<ProtectedRoute component={PartnerDetail} />}
                />

                <Route
                  path="partners/:partnerID/apps/create"
                  element={<ProtectedRoute component={PartnerAppCreate} />}
                />

                <Route
                  path="partners/:partnerID/apps/:appID/clients/create"
                  element={<ProtectedRoute component={AppClientCreate} />}
                />

                <Route
                  path="apps"
                  element={<ProtectedRoute component={Apps} />}
                />

                <Route
                  path="apps/:appID"
                  element={<ProtectedRoute component={AppDetail} />}
                />

                <Route
                  path="dev"
                  element={<ProtectedRoute component={DevTools} />}
                />

                <Route
                  path="*"
                  element={
                    <div>
                      <h1>Page Not Found</h1>
                    </div>
                  }
                />
              </Route>
            </SentryRoutes>
          </MoneyKitModeProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </BrowserRouter>
  );
};

export default App;
