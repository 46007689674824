import { MoneyKitMode } from "./types";

export type AppEnv = "local" | "staging" | "production";

export const auth0AudienceAuthGateway = import.meta.env
  .VITE_AUTH0_AUDIENCE_AUTH_GATEWAY;
export const auth0ClientID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
export const moneykitAPIHost = import.meta.env.VITE_MONEYKIT_API_HOST;
export const moneyAppAPIHost = import.meta.env.VITE_MONEY_APP_API_HOST;
export const connectDemoHost = import.meta.env.VITE_CONNECT_DEMO_HOST;

export const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
export const sentryEnv = import.meta.env.VITE_SENTRY_ENVIRONMENT;

// Vercel-only (deployed environment) vars
type VercelEnv = "production" | "preview" | "development";
export const vercelEnv: VercelEnv | undefined = import.meta.env.VITE_VERCEL_ENV;

export const appEnv: AppEnv = (() => {
  switch (vercelEnv) {
    case "production":
      return "production";
    case "preview":
      return "staging";
    case "development":
      return "local";
    default:
      return "local";
  }
})();

export const moneykitModes: MoneyKitMode[] = [
  MoneyKitMode.sandbox,
  MoneyKitMode.live,
];
