import { titleCase } from "./strings";
import type { Provider } from "../api/types";
import {
  AkoyaIcon,
  FinicityIcon,
  MXIcon,
  MoneyKitIcon,
  PlaidIcon,
  YodleeIcon,
} from "../components/provider-icons";

export const prettyProviderName = (provider: Provider) => {
  switch (provider) {
    case "mx":
      return "MX";
    case "moneykit":
      return "MoneyKit";
    default:
      return titleCase(provider);
  }
};

export function colorForProvider(provider: Provider): string {
  switch (provider) {
    case "mx":
      return "#000000";
    case "finicity":
      return "#5D00F3";
    case "plaid":
      return "#777878";
    case "yodlee":
      return "#007DFF";
    case "akoya":
      return "#FF0EA0";
    case "moneykit":
      return "#C02EED";
    default:
      return "";
  }
}

export function imageUrlForProvider(provider: Provider): string {
  switch (provider) {
    case "mx":
      return "/images/providers/mx.png";
    case "finicity":
      return "/images/providers/finicity.png";
    case "plaid":
      return "/images/providers/plaid.png";
    case "yodlee":
      return "/images/providers/yodlee.png";
    case "akoya":
      return "/images/providers/akoya.png";
    case "moneykit":
      return "/images/providers/moneykit.png";
    default:
      return "";
  }
}

export function iconComponentForProvider(
  provider: Provider
): [React.FC<{ size?: number }>, defaultSize?: number] {
  switch (provider) {
    case "mx":
      return [MXIcon, 24];
    case "finicity":
      return [FinicityIcon, 20];
    case "plaid":
      return [PlaidIcon, 18];
    case "yodlee":
      return [YodleeIcon, 18];
    case "akoya":
      return [AkoyaIcon, 24];
    case "moneykit":
      return [MoneyKitIcon, 18];
    default:
      return [MoneyKitIcon, 24];
  }
}

export const prettyProviderID = (pid: string) => {
  return pid.length === 36 ? pid.slice(0, 4) + "..." + pid.slice(-4) : pid;
};
