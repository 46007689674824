import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 18}
    height={props.height ?? 18}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.25 4.5A4.25 4.25 0 0 1 4.5.25h4a4.25 4.25 0 0 1 4.25 4.25v.75h.75a4.25 4.25 0 0 1 4.25 4.25v4a4.25 4.25 0 0 1-4.25 4.25h-4a4.25 4.25 0 0 1-4.25-4.25v-.75H4.5A4.25 4.25 0 0 1 .25 8.5v-4Zm5 5.75V9.5A4.25 4.25 0 0 1 9.5 5.25h.75V4.5A1.75 1.75 0 0 0 8.5 2.75h-4A1.75 1.75 0 0 0 2.75 4.5v4c0 .966.784 1.75 1.75 1.75h.75Zm2.5-.75c0-.966.784-1.75 1.75-1.75h4c.966 0 1.75.784 1.75 1.75v4a1.75 1.75 0 0 1-1.75 1.75h-4a1.75 1.75 0 0 1-1.75-1.75v-4Z"
      clipRule="evenodd"
    ></path>
  </svg>
);
export default SvgComponent;
