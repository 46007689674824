import { useEffect, useState } from "react";

type Props = {
  children?: React.ReactNode;
  title: string;
  alwaysShowBottomBorder?: boolean;
};

const TitleBar = ({ children, title, alwaysShowBottomBorder }: Props) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > 10 && !scrolled) {
        setScrolled(true);
      } else if (currentScrollPos <= 10 && scrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  return (
    <header className="flex-none px-6 py-4 border-b border-b-separator-tertiary text-foreground-primary">
      <div className="relative flex items-center justify-between">
        <h3 className="text-2xl font-bold">{title}</h3>
        {children}
      </div>
    </header>
  );
};

export default TitleBar;
