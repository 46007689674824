import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "sonner";

import Loading from "../components/loading";
import Sidebar from "../components/sidebar";

const Layout = () => {
  return (
    <div className="flex w-full">
      <Toaster
        toastOptions={{
          style: {
            borderRadius: 16,
          },
        }}
      />

      <Sidebar />

      <main className="self-stretch flex-1">
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};

export default Layout;
