import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const [error, setError] = useState("");

  const logoStyle =
    "block bg-foreground-primary w-[208px] h-[42px] translate-x-1.5 mx-auto mt-0 mb-10";
  const buttonStyle = `block bg-foreground-primary text-background-primary mt-6 mx-0 mb-0 w-full h-12 font-semibold text-lg rounded-full appearance-none active:opacity-50
    dark:bg-fill-primary dark:text-foreground-primary`;

  const login = async () => {
    try {
      const res = await loginWithRedirect();
      console.log("Login success", res);
    } catch (error: unknown) {
      console.error(error);
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  return (
    <main className="flex flex-col items-center justify-center w-screen h-screen mx-auto bg-background-primary">
      <div className="w-full max-w-xs">
        <div
          className={logoStyle}
          style={{
            maskImage: "url(/images/login-logo.svg)",
            WebkitMaskImage: "url(/images/login-logo.svg)",
            maskPosition: "center center",
            WebkitMaskPosition: "center center",
            maskSize: "208px 42px",
            WebkitMaskSize: "208px 42px",
            maskRepeat: "208px 42px",
            WebkitMaskRepeat: "no-repeat",
          }}
        ></div>

        <form className="w-full m-w-auto">
          {error && (
            <span className="block w-full font-bold text-center text-state-error">
              {error}
            </span>
          )}

          <button onClick={login} className={buttonStyle}>
            Sign In
          </button>
        </form>
      </div>
    </main>
  );
};

export default Login;
