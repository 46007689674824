import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useAccessToken = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (e) {
        console.error("Error getting access token.");
        console.error(e);
      }
    };

    isAuthenticated && getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return accessToken;
};
