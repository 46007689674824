import { LoginType } from "../api/types";

export const titleCase = (s?: string): string => {
  if (!s) {
    return "";
  }
  const parts = s.split(/\s|_|-/);
  const titleizedParts = parts.map((part) =>
    part.trim().length > 0
      ? `${part[0].toUpperCase()}${part.substring(1)}`
      : part
  );
  return titleizedParts.join(" ");
};

export const prettyLoginTypeName = (loginType: LoginType) => {
  switch (loginType) {
    case "oauth":
      return "OAuth";
    case "credentials":
      return "Credentials";
    default:
      return titleCase(loginType);
  }
};

export const prettySdkName = (sdk: string) => {
  switch (sdk) {
    case "ios":
      return "iOS";
    default:
      return titleCase(sdk);
  }
};
