import { ReactNode, useEffect, useState } from "react";

import IconCopy from "./images/copy-to-clipboard";
import IconCheck from "../images/checkmark";
import { copyToClipboard } from "../util/clipboard";

type Props = {
  children?: ReactNode;
  content: string;
};

const CopyButton = ({ children, content }: Props) => {
  const [isCopying, setIsCopying] = useState(false);

  useEffect(() => {
    if (isCopying) {
      const timeout = setTimeout(() => {
        setIsCopying(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isCopying]);

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsCopying(true);
        copyToClipboard(content);
      }}
      className="flex items-center w-4 h-4 text-foreground-secondary"
      disabled={isCopying}
    >
      {children}
      {/* Wrapping div around image with set width prevents layout jitter when icon dimensions change. */}
      {isCopying ? (
        <IconCheck width={14} height={11} />
      ) : (
        <IconCopy width={16} height={16} />
      )}
    </button>
  );
};

export default CopyButton;
