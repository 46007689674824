export const queryKeyLink = "link";
export const queryKeyLinks = "links";
export const queryKeyApp = "app";
export const queryKeyApps = "apps";
export const queryKeyAppUserCountDatesReport = "app_user_count_dates_report";
export const queryKeyPartner = "partner";
export const queryKeyPartners = "partners";
export const queryKeyPartnerScopes = "partner_scopes";
export const queryKeyPartnerUsage = "partner_usage";
export const queryKeyAppUsage = "app_usage";
export const queryKeyInstitution = "institution";
export const queryKeyInstitutions = "institutions";
export const queryKeyLinkConversions = "link_conversions";
export const queryKeyLinksCountReport = "links_count_report";
export const queryKeyLinksStability = "link_stability_report";
export const queryKeyProvidersBreakdown = "providers_breakdown";
export const queryKeyStats = "stats";
