import { ComponentProps } from "react";

const Button = (props: ComponentProps<"button">) => {
  return (
    <button
      className="px-4 h-12 flex items-center rounded-xl text-base font-bold bg-foreground-primary text-background-primary fill-background-primary active:opacity-75"
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
