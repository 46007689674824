import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import queryString from "query-string";

import { useAccessToken } from "./auth";
import {
  queryKeyApp,
  queryKeyAppUsage,
  queryKeyApps,
  queryKeyInstitution,
  queryKeyInstitutions,
  queryKeyLink,
  queryKeyLinkConversions,
  queryKeyLinks,
  queryKeyLinksCountReport,
  queryKeyLinksStability,
  queryKeyPartner,
  queryKeyPartnerScopes,
  queryKeyPartnerUsage,
  queryKeyPartners,
  queryKeyProvidersBreakdown,
} from "./query-keys";
import {
  AppClientWithSecret,
  AppLink,
  AppLinkEndState,
  AppLinkState,
  AppResponse,
  AppUsage,
  AppsRepsonse,
  CreateAppClientRequest,
  CreatePartnerAppRequest,
  CreatePartnerRequest,
  CreatePartnerTeamUserRequest,
  CreatePartnerTeamUserResponse,
  Institution,
  InstitutionRoute,
  LinkConversionsBreakdown,
  LinksCountReport,
  LoginType,
  PagedInstitutionResponse,
  PagedLinkResponse,
  Partner,
  PartnerApp,
  PartnerScopesResponse,
  PartnerUsageCounts,
  Provider,
  QueryOptions,
  SearchGroup,
  Stats,
} from "./types";
import { lastWeek, now } from "../components/date-range-dropdown-picker";
import { moneykitAPIHost } from "../config";
import { MoneyKitMode } from "../types";

const appsPath = "apps";
const appPath = (appID: string) => `apps/${appID}`;
const appLinksPath = "app-links";
const partnersPath = "partners";
const partnerPath = (partnerID: string) => `partners/${partnerID}`;
const partnerAppsPath = (partnerID: string) => `partners/${partnerID}/apps`;
const appUsagePath = (appID: string) => `apps/${appID}/usage`;
const partnerTeamUsersPath = (partnerID: string) =>
  `partners/${partnerID}/team/users`;
const partnerAppClientsPath = (partnerID: string, appID: string) =>
  `partners/${partnerID}/apps/${appID}/clients`;
const partnerScopesPath = "partners/scopes";
const partnerUsagePath = (partnerID: string) => `partners/${partnerID}/usage`;
const institutionPath = (id: string) => `institutions/${id}`;
const institutionsPath = "institutions";
const institutionImagePath = (
  imageType: InstitutionImageType,
  institutionID: string
) => `institutions/${institutionID}/${imageType}`;
const linkConversionsBreakdownPath = "conversion";
const statsPath = "stats";
const linkStabilityAgeBucketsPath = "reports/link-stability/age/buckets";
const appLinkPath = (appLinkExternalID: string) =>
  `app-links/${appLinkExternalID}`;
const linksCountReportPath = "reports/links/count";
const institutionRouteDisablePath = (provider: Provider, routeID: string) =>
  `/routes/${provider}.${routeID}/disable`;
const institutionRouteEnablePath = (provider: Provider, routeID: string) =>
  `/routes/${provider}.${routeID}/enable`;
const institutionRouteReassignPath = (provider: Provider, routeID: string) =>
  `/routes/${provider}.${routeID}/institution`;
const mergeInstitutionPath = (fromID: string) => `/institutions/${fromID}`;

type ReqParamsGetInstitutions = {
  mode: MoneyKitMode;
  name?: string;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  featured?: boolean;
};

export type InstitutionImageType =
  | "avatar"
  | "avatar_dark"
  | "logo"
  | "logo_dark";

export type ReqParamsUpdateInstitution = {
  external_id?: string;
  display_domain?: string | null;
  featured_position?: number | null;
  forgot_password_url?: string | null;
  name?: string;
  search_group?: SearchGroup;
  search_aliases?: string[] | null;
  color?: string;
  color_dark?: string | null;
  avatar?: string | null;
  avatar_dark?: string | null;
  logo?: string | null;
  logo_dark?: string | null;
};

export type ReqParamsMergeInstitution = {
  intoID: string;
};

export type ReqParamsReassignInstitutionRoute = {
  institutionID: string;
};

type ReqParamsGetLinksCountReport = {
  mode: MoneyKitMode;
  start?: string;
  end?: string;
  partnerID?: string;
};

type ReqParamsDateRange = {
  end: string;
  start: string;
};

type ReqParamsGetLinks = {
  mode: MoneyKitMode;
  appLinkState?: AppLinkState;
  institutionID?: string;
  appID?: string;
  pageSize?: number;
};

type ReqParamsGetInfiniteLinks = {
  mode: MoneyKitMode;
  appLinkState?: AppLinkState;
  lastAttemptState?: AppLinkEndState;
  institutionID?: string;
  appID?: string;
  provider?: Provider;

  pageSize?: number;
  page?: number;

  startDate?: string;
  endDate?: string;
};

type ReqParamsGetApps = {
  mode: MoneyKitMode;
};

export type ReqParamsUpdateApp = {
  name?: string | null;
  product_url?: string | null;
  description?: string | null;
  business_address?: string | null;
  support_email?: string | null;
  support_phone?: string | null;
  support_url?: string | null;
  allowed_redirect_uris?: string[] | null;
  theme_color?: string | null;
  avatar?: string | null;
  logo?: string | null;
  set_prod_reason?: string | null;
  connected_links_limit?: number | null;
};

const baseHttpOptions = {
  timeout: 5000,
  headers: {},
};

const privateAPIBaseURL = (host: string) => `${host}/private/`;

const http = axios.create({
  ...baseHttpOptions,
  baseURL: privateAPIBaseURL(moneykitAPIHost),
});

const authHeader = (token: string) => ({ Authorization: `Bearer ${token}` });
const jsonContentHeader = { "Content-Type": "application/json" };
const multipartContentHeader = { "Content-Type": "multipart/form-data" };

export const getInstitution = async (
  id: string,
  token: string
): Promise<Institution> => {
  try {
    const response = await http.get(institutionPath(id), {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    throw new Error(`Get Institution ID "${id}" request failed`);
  }
};

export const useInstitutionQuery = (id: string, options: QueryOptions = {}) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyInstitution, id, accessToken],
    () => getInstitution(id, accessToken),
    { enabled: !!(accessToken && id), ...options }
  );
};

export const getInstitutions = async (
  {
    mode,
    name,
    orderBy,
    page,
    featured,
    pageSize = 20,
  }: ReqParamsGetInstitutions,
  token: string
): Promise<PagedInstitutionResponse> => {
  const defaultOrderBy = "featured_position";

  const url = queryString.stringifyUrl(
    {
      url: institutionsPath,
      query: {
        mode,
        name,
        page,
        order_by: orderBy ?? defaultOrderBy,
        size: pageSize,
        featured,
      },
    },
    { skipEmptyString: true, skipNull: true }
  );

  try {
    const response = await http.get(url, {
      headers: authHeader(token),
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    throw new Error("Get Institutions API request failed");
  }
};

// Hook to request Institutions from MoneyKit API.
// Returns an Infinite Query.
export const useInstitutionsInfiniteQuery = (
  params: ReqParamsGetInstitutions
) => {
  const isNameFilterValid = validateSearchFilterLength(params.name);
  const accessToken = useAccessToken();

  return useInfiniteQuery({
    queryKey: [queryKeyInstitutions, params, accessToken],
    queryFn: ({ pageParam = 1 }) =>
      getInstitutions({ ...params, page: pageParam }, accessToken),
    getNextPageParam: getPagedResponseNextPage,
    enabled: !!accessToken && isNameFilterValid,
    refetchOnWindowFocus: false,
  });
};

const updateInstitution = async (
  id: string,
  params: ReqParamsUpdateInstitution,
  token: string
): Promise<Institution> => {
  try {
    const response = await http.patch(institutionPath(id), params, {
      headers: { ...authHeader(token), ...jsonContentHeader },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Update Institution ID "${id}" API request failed`);
  }
};

type UpdateInstitutionMutation = {
  institutionID: string;
  update: ReqParamsUpdateInstitution;
};

export const useUpdateInstitutionMutation = () => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (mutation: UpdateInstitutionMutation) => {
      return updateInstitution(
        mutation.institutionID,
        mutation.update,
        accessToken
      );
    },
    {
      onSuccess: (institution) => {
        queryClient.invalidateQueries([
          queryKeyInstitution,
          institution.external_id,
        ]);
      },
    }
  );
};

const deleteInstitutionImage = async (
  imageType: InstitutionImageType,
  institutionID: string,
  token: string
) => {
  try {
    await http.delete(institutionImagePath(imageType, institutionID), {
      headers: authHeader(token),
    });
  } catch (error) {
    throw new Error(
      `Delete Institution ID "${institutionID}" ${imageType} image API request failed`
    );
  }
};

export const useDeleteInstitutionImageMutation = () => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { imageType: InstitutionImageType; institutionID: string }) => {
      return deleteInstitutionImage(
        params.imageType,
        params.institutionID,
        accessToken
      );
    },
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries([
          queryKeyInstitution,
          variables.institutionID,
        ]);
      },
    }
  );
};

export const disableInstitutionRoute = async (
  provider: Provider,
  routeID: string,
  token: string,
  reason?: string
): Promise<InstitutionRoute> => {
  try {
    const response = await http.post(
      institutionRouteDisablePath(provider, routeID),
      {
        reason,
      },
      { headers: { ...authHeader(token) } }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Disable Institution Route "${provider} ${routeID}" API request failed`
    );
  }
};

export const enableInstitutionRoute = async (
  provider: Provider,
  routeID: string,
  token: string,
  reason?: string
): Promise<InstitutionRoute> => {
  try {
    const response = await http.post(
      institutionRouteEnablePath(provider, routeID),
      {
        reason,
      },
      { headers: { ...authHeader(token) } }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Enable Institution Route "${provider} ${routeID}" API request failed`
    );
  }
};

export const mergeInstitution = async (
  fromID: string,
  { intoID }: ReqParamsMergeInstitution,
  token: string
): Promise<Institution> => {
  const url = queryString.stringifyUrl(
    {
      url: mergeInstitutionPath(fromID),
      query: { into_id: intoID },
    },
    { skipEmptyString: true, skipNull: true }
  );
  return http
    .put(url, {}, { headers: { ...authHeader(token) } })
    .then((response) => response.data)
    .catch((error) => apiErrorHandler("Institution merge failed:", error));
};

export const reassignInstitutionRoute = async (
  provider: Provider,
  routeID: string,
  { institutionID }: ReqParamsReassignInstitutionRoute,
  token: string
): Promise<InstitutionRoute> => {
  return http
    .put(
      institutionRouteReassignPath(provider, routeID),
      { institution_id: institutionID },
      { headers: { ...authHeader(token) } }
    )
    .then((response) => response.data)
    .catch((error) =>
      apiErrorHandler("Institution route reassignment failed:", error)
    );
};

export const useMergeInstitutionMutation = () => {
  const accessToken = useAccessToken();

  return useMutation(
    ({
      fromID,
      params,
    }: {
      fromID: string;
      params: ReqParamsMergeInstitution;
    }) => {
      return mergeInstitution(fromID, params, accessToken);
    }
  );
};

export type InstitutionImageUploadResponse = {
  url: string;
  other?: string[];
};

const uploadInstitutionImage = async (
  imageType: InstitutionImageType,
  imageFile: File,
  institutionID: string,
  token: string
): Promise<InstitutionImageUploadResponse> => {
  try {
    const formData = new FormData();
    formData.append("image", imageFile);
    const response = await http.post(
      institutionImagePath(imageType, institutionID),
      formData,
      {
        headers: { ...authHeader(token), ...multipartContentHeader },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Upload Institution ID "${institutionID}" ${imageType} image API request failed`
    );
  }
};

export const useUploadInstitutionImageMutation = () => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (params: {
      imageType: InstitutionImageType;
      imageFile: File;
      institutionID: string;
    }) => {
      return uploadInstitutionImage(
        params.imageType,
        params.imageFile,
        params.institutionID,
        accessToken
      );
    },
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries([
          queryKeyInstitution,
          variables.institutionID,
        ]);
      },
    }
  );
};

const getLinks = async (
  { mode, appLinkState, institutionID, appID, pageSize }: ReqParamsGetLinks,
  token: string
): Promise<AppLink[]> => {
  const url = queryString.stringifyUrl(
    {
      url: appLinksPath,
      query: {
        mode,
        app_link_state: appLinkState,
        institution_id: institutionID,
        partner_app_id: appID,
        page_size: pageSize,
      },
    },
    { skipEmptyString: true, skipNull: true }
  );

  try {
    const response = await http.get(url, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    throw new Error("Get App Links API request failed");
  }
};

export const useLinksQuery = (
  params: ReqParamsGetLinks,
  options: QueryOptions
) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyLinks, params, accessToken],
    () => getLinks(params, accessToken),
    { enabled: !!accessToken, ...options }
  );
};

export const getInfiniteLinks = async (
  {
    mode,
    institutionID,
    appID,
    provider,
    appLinkState,
    lastAttemptState,
    pageSize = 20,
    page,
    startDate,
    endDate,
  }: ReqParamsGetInfiniteLinks,
  token: string
): Promise<PagedLinkResponse> => {
  const url = queryString.stringifyUrl(
    {
      url: appLinksPath,
      query: {
        mode,
        app_link_state: appLinkState,
        last_attempt_state: lastAttemptState,
        institution_id: institutionID,
        app_id: appID,
        provider: provider,

        size: pageSize,
        page,

        start_date: startDate,
        end_date: endDate,
      },
    },
    { skipEmptyString: true, skipNull: true }
  );

  try {
    const response = await http.get(url, {
      headers: authHeader(token),
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("getInfiniteLinks failed");
  }
};

export const useLinksInfiniteQuery = (params: ReqParamsGetInfiniteLinks) => {
  const accessToken = useAccessToken();

  return useInfiniteQuery({
    queryKey: [queryKeyLinks, params, accessToken],
    queryFn: ({ pageParam = 1 }) =>
      getInfiniteLinks({ ...params, page: pageParam }, accessToken),
    getNextPageParam: getPagedResponseNextPage,
    enabled: !!accessToken,
    refetchOnWindowFocus: false,
  });
};

export const getLink = async (id: string, token: string): Promise<AppLink> => {
  try {
    const response = await http.get(appLinkPath(id), {
      headers: authHeader(token),
      timeout: 10000,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Get App Link ID "${id}" API request failed`);
  }
};

export const useLinkQuery = (id: string, options?: QueryOptions) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyLink, id, accessToken],
    () => getLink(id, accessToken),
    { enabled: !!id && !!accessToken, ...options }
  );
};

const getLinksCountReport = async (
  { mode, start, end, partnerID }: ReqParamsGetLinksCountReport,
  token: string
): Promise<LinksCountReport> => {
  const url = queryString.stringifyUrl({
    url: linksCountReportPath,
    query: { mode, start, end, partner_id: partnerID },
  });

  try {
    const response = await http.get(url, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    throw new Error("Get Links Count Report API request failed");
  }
};

// Hook to request Links Count Report from MoneyKit API.
export const useLinksCountReportQuery = (
  { mode, start, end, partnerID }: ReqParamsGetLinksCountReport,
  { refetchInterval }: QueryOptions
) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyLinksCountReport, start, end, accessToken],
    () => getLinksCountReport({ mode, start, end, partnerID }, accessToken),
    { refetchInterval, enabled: !!accessToken }
  );
};

const getPartners = async (token: string): Promise<Partner[]> => {
  try {
    const response = await http.get(partnersPath, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    throw new Error("Get Partners API request failed");
  }
};

export const usePartnersQuery = () => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyPartners, accessToken],
    () => getPartners(accessToken),
    { enabled: !!accessToken }
  );
};

export const getPartner = async (
  id: string,
  token: string
): Promise<Partner> => {
  try {
    const response = await http.get(partnerPath(id), {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    throw new Error("Get Partner API request failed");
  }
};

export const usePartnerQuery = (id: string) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyPartner, id, accessToken],
    () => getPartner(id, accessToken),
    { enabled: !!id && !!accessToken }
  );
};

const getAppUsage = async (
  appId: string,
  { start, end }: ReqParamsDateRange,
  token: string
): Promise<AppUsage> => {
  const url = queryString.stringifyUrl({
    url: appUsagePath(appId),
    query: {
      start_at: start,
      end_at: end,
    },
  });

  try {
    const response = await http.get(url, {
      headers: authHeader(token),
    });
    console.log("get app usage response", response);
    return response.data;
  } catch (error) {
    throw new Error("Get App Usage API request failed");
  }
};

export const useAppUsageQuery = (
  appId: string,
  { start, end }: ReqParamsDateRange
) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyAppUsage, appId, accessToken],
    () => getAppUsage(appId, { start, end }, accessToken),
    { enabled: !!accessToken }
  );
};

const createPartner = async (
  newPartner: CreatePartnerRequest,
  token: string
): Promise<Partner> => {
  try {
    const response = await http.post(partnersPath, newPartner, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Create Partner API request failed");
  }
};

export const useCreatePartnerMutation = () => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (newPartner: CreatePartnerRequest) => {
      return createPartner(newPartner, accessToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeyPartners]);
      },
    }
  );
};

const createPartnerApp = async (
  partnerId: string,
  newPartnerApp: CreatePartnerAppRequest,
  token: string
): Promise<PartnerApp> => {
  try {
    const response = await http.post(
      partnerAppsPath(partnerId),
      newPartnerApp,
      {
        headers: authHeader(token),
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Create Partner App API request failed");
  }
};

export const useCreatePartnerAppMutation = (partnerId: string) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (newPartnerApp: CreatePartnerAppRequest) => {
      return createPartnerApp(partnerId, newPartnerApp, accessToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeyPartner, partnerId]);
      },
    }
  );
};

const createPartnerTeamUser = async (
  partnerId: string,
  user: CreatePartnerTeamUserRequest,
  token: string
): Promise<CreatePartnerTeamUserResponse> => {
  try {
    const response = await http.post(partnerTeamUsersPath(partnerId), user, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Create Partner Team User API request failed");
  }
};

export const useCreatePartnerTeamUserMutation = (partnerId: string) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (user: CreatePartnerTeamUserRequest) => {
      return createPartnerTeamUser(partnerId, user, accessToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeyPartner, partnerId]);
      },
    }
  );
};

const createAppClient = async (
  partnerId: string,
  appId: string,
  newAppClient: CreateAppClientRequest,
  token: string
): Promise<AppClientWithSecret> => {
  try {
    const response = await http.post(
      partnerAppClientsPath(partnerId, appId),
      newAppClient,
      {
        headers: authHeader(token),
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Create app client request failed");
  }
};

export const useCreateAppClientMutation = (
  partnerId: string,
  appId: string
) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (newAppClient: CreateAppClientRequest) => {
      return createAppClient(partnerId, appId, newAppClient, accessToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeyPartner, partnerId]);
      },
    }
  );
};

const getPartnerScopes = async (
  token: string
): Promise<PartnerScopesResponse> => {
  try {
    const response = await http.get(partnerScopesPath, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    throw new Error("Get Partner Scopes API request failed");
  }
};

export const useAppQuery = (appID: string) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyApp, "apps", appID, accessToken],
    () => getApp(appID, accessToken),
    { enabled: !!accessToken && !!appID }
  );
};

export const getApp = async (
  appID: string,
  token: string
): Promise<AppResponse> => {
  try {
    const response = await http.get(appPath(appID), {
      headers: authHeader(token),
      timeout: 15000, // TODO: remove this once the query is optimized on the backend
    });
    return response.data;
  } catch (error) {
    throw new Error("Get App API request failed");
  }
};

export const useAppsQuery = (params: ReqParamsGetApps) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyApps, params, accessToken],
    () => getApps(params, accessToken),
    { enabled: !!accessToken }
  );
};

export const getApps = async (
  { mode }: ReqParamsGetApps,
  token: string
): Promise<AppsRepsonse> => {
  try {
    const response = await http.get(appsPath, {
      headers: authHeader(token),
      params: {
        mode,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Get Apps API request failed");
  }
};

const updateApp = async (
  id: string,
  params: ReqParamsUpdateApp,
  token: string
): Promise<AppResponse> => {
  try {
    const response = await http.post(appPath(id), params, {
      headers: { ...authHeader(token), ...jsonContentHeader },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Update App ID "${id}" API request failed`);
  }
};

type UpdateAppMutation = {
  appID: string;
  update: ReqParamsUpdateApp;
};

export const useUpdateAppMutation = () => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (mutation: UpdateAppMutation) => {
      return updateApp(mutation.appID, mutation.update, accessToken);
    },
    {
      onSuccess: (app, variables) => {
        queryClient.invalidateQueries([queryKeyApp, variables.appID]);
      },
    }
  );
};

const deleteApp = async (appID: string, token: string) => {
  try {
    await http.delete(appPath(appID), {
      headers: authHeader(token),
    });
  } catch (error) {
    throw new Error(`Delete App with ID "${appID}" request failed`);
  }
};

export const useDeleteAppMutation = () => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { appID: string }) => {
      return deleteApp(params.appID, accessToken);
    },
    {
      onSuccess(data, variables) {
        queryClient.invalidateQueries([queryKeyInstitution, variables.appID]);
      },
    }
  );
};

export const usePartnerScopesQuery = () => {
  const accessToken = useAccessToken();

  return useQuery([queryKeyPartnerScopes, accessToken], () =>
    getPartnerScopes(accessToken)
  );
};

const getPartnerUsage = async (
  partnerId: string,
  { start, end }: ReqParamsDateRange,
  token: string
): Promise<PartnerUsageCounts> => {
  const url = queryString.stringifyUrl({
    url: partnerUsagePath(partnerId),
    query: { start, end },
  });

  try {
    const response = await http.get(url, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (error) {
    throw new Error("Get Partner Usage API request failed");
  }
};

export const usePartnerUsageQuery = (
  partnerId: string,
  { start, end }: ReqParamsDateRange
) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyPartnerUsage, partnerId, accessToken],
    () => getPartnerUsage(partnerId, { start, end }, accessToken),
    { enabled: !!accessToken }
  );
};

const getLinkConversionsBreakdown = async (
  mode: MoneyKitMode,
  token: string,
  customRange: DaysCustomRange | null,
  provider: string | null,
  app_id: string | null,
  institution_id: string | null,
  login_type: LoginType | null,
  sdk: string | null
): Promise<LinkConversionsBreakdown> => {
  try {
    const response = await http.get(linkConversionsBreakdownPath, {
      headers: authHeader(token),
      params: {
        mode,
        start_at: customRange?.start_at,
        end_at: customRange?.end_at,
        provider: provider,
        app_id,
        institution_id,
        login_type,
        sdk,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Get link conversions breakdown API request failed");
  }
};

type DaysCustomRange = {
  start_at: string | null;
  end_at: string | null;
};

export const useLinkConversionsBreakdownQuery = (
  mode: MoneyKitMode,
  customRange: DaysCustomRange,
  provider: string | null,
  appID: string | null,
  institutionID: string | null,
  loginType: LoginType | null,
  sdk: string | null
) => {
  const accessToken = useAccessToken();
  const enabled =
    !!accessToken &&
    (customRange.start_at !== null || customRange.end_at !== null);

  return useQuery(
    [
      queryKeyLinkConversions,
      accessToken,
      mode,
      customRange,
      provider,
      appID,
      institutionID,
      loginType,
      sdk,
    ],
    () =>
      getLinkConversionsBreakdown(
        mode,
        accessToken,
        customRange,
        provider,
        appID,
        institutionID,
        loginType,
        sdk
      ),
    { enabled: enabled, refetchOnWindowFocus: false }
  );
};

const getStats = async (
  mode: MoneyKitMode,
  token: string,
  start: string,
  end: string,
  appID: string | null,
  raw?: boolean,
  previous?: boolean
): Promise<Stats> => {
  try {
    const startAtDate = new Date(start);
    const endAtDate = new Date(end);

    const response = await http.get(statsPath, {
      headers: authHeader(token),
      params: {
        mode,
        start_at: startAtDate.toISOString(),
        end_at: endAtDate.toISOString(),
        app_id: appID,
        intervals: 1,
        raw: raw ?? false,
        previous: previous ?? true,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(`Get provider breakdown API request failed`);
  }
};

export const useStats = (
  mode: MoneyKitMode,
  customRange: DaysCustomRange,
  appID: string | null,
  raw?: boolean,
  previous?: boolean,
  options?: QueryOptions
) => {
  const accessToken = useAccessToken();

  return useQuery(
    [
      queryKeyProvidersBreakdown,
      mode,
      customRange,
      appID,
      raw,
      previous,
      accessToken,
    ],
    () =>
      getStats(
        mode,
        accessToken,
        customRange.start_at ?? now.toString(),
        customRange.end_at ?? lastWeek.toString(),
        appID,
        raw,
        previous
      ),
    { enabled: !!accessToken, ...options }
  );
};

const getLinkStabilityReport = async (
  mode: MoneyKitMode,
  token: string
): Promise<LinkConversionsBreakdown> => {
  try {
    const response = await http.get(linkStabilityAgeBucketsPath, {
      headers: authHeader(token),
      params: {
        mode,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Get link stability API request failed");
  }
};

export const useLinksStabilityReportQuery = (mode: MoneyKitMode) => {
  const accessToken = useAccessToken();

  return useQuery(
    [queryKeyLinksStability, accessToken],
    () => getLinkStabilityReport(mode, accessToken),
    { enabled: !!accessToken }
  );
};

function validateSearchFilterLength(search: string | undefined) {
  const minimumNameFilterLength = 2;
  const nameFilterLength = search ? search.length : 0;
  return nameFilterLength === 0 || nameFilterLength >= minimumNameFilterLength;
}

// Determine from a paged response whether there are further pages to request.
function getPagedResponseNextPage({
  page,
  size,
  total,
}: {
  page: number;
  size: number;
  total: number;
}) {
  return page * size < total ? page + 1 : null;
}

function apiErrorHandler(prefix: string, error: unknown) {
  let message = "";
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // Account for different error response formats from MoneyKit API.
      const responseData = error.response.data as
        | { detail: { message: string } }
        | ValidationErrorResponse;
      if ("detail" in responseData) {
        message = `${responseData.detail.message}`;
      } else {
        message = `${
          responseData.error_message
        } - ${responseData.validation_errors
          .map((err) => `${err.location.join(".")} ${err.message}`)
          .join(", ")}`;
      }
    } else if (error.request) {
      message = JSON.stringify(error.request);
    } else {
      message = error.message;
    }
  } else if (error instanceof Error) {
    message = error.message;
  } else {
    message = `${error}`;
  }

  console.error(`${prefix} ${message}`);
  throw new Error(`${prefix} ${message}`);
}

type ValidationErrorResponse = {
  error_message: string;
  validation_errors: { message: string; location: string[] }[];
};
