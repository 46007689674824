import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.586.586a2 2 0 1 1 2.828 2.828L11.828 9l5.586 5.586a2 2 0 1 1-2.828 2.828L9 11.828l-5.586 5.586a2 2 0 1 1-2.828-2.828L6.172 9 .586 3.414A2 2 0 1 1 3.414.586L9 6.172 14.586.586Z"
    />
  </svg>
);
export default SvgComponent;
