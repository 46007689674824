const Loading = ({ text }: { text?: string }) => (
  <div className="flex flex-col items-center justify-center gap-4 text-center">
    <div className="animate-scale">
      <div className="grid grid-cols-2 grid-rows-2 gap-1 animate-scale-spin">
        <LoadingCircle />
        <LoadingCircle />
        <LoadingCircle />
        <LoadingCircle />
      </div>
    </div>
    <h2 className="text-xl font-bold text-foreground-primary">{text}</h2>
  </div>
);

const LoadingCircle = () => (
  <div className="w-4 h-4 rounded-full bg-foreground-tertiary animate-pulse"></div>
);

export default Loading;
