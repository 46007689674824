import clsx from "clsx";
import { forwardRef } from "react";

type InputProps = {
  label: string;
  description?: string;
  labelSize?: "small" | "base";
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, description, labelSize, ...props }, ref) => {
    return (
      <label>
        <span
          className={clsx(
            "font-bold uppercase text-foreground-secondary pb-1 block",
            labelSize === "small" ? "text-sm" : "text-base"
          )}
        >
          {label}
        </span>
        {description && (
          <span className="block max-w-xl mb-1 text-foreground-tertiary">
            {description}
          </span>
        )}
        <input
          {...props}
          ref={ref}
          // order matters. make sure className is last
          // otherwise the props spread will overwrite it
          className={clsx(
            "rounded-xl bg-fill-tertiary hover:bg-fill-primary focus:bg-fill-primary font-bold px-4 h-12 border-none w-full",
            props.className
          )}
        />
      </label>
    );
  }
);

Input.displayName = "Input";

export default Input;
