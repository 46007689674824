import Select from "./select";
import { moneykitModes } from "../config";
import { useMoneyKitMode } from "../providers/moneykit-mode";
import { MoneyKitMode } from "../types";
import { titleCase } from "../util/strings";

const optionForMode = (mode: MoneyKitMode) => ({
  value: mode,
  label: titleCase(mode),
});

const moneyKitModeOptions = moneykitModes.map(optionForMode);

type MoneyKitModeOption = {
  value: MoneyKitMode;
  label: string;
};

const ModeSelector = () => {
  const { mode, setMode } = useMoneyKitMode();
  const activeModeOption = optionForMode(mode);

  const onChange = (selectedOption: MoneyKitModeOption | null) =>
    selectedOption && setMode(selectedOption.value);

  return (
    <Select
      onChange={onChange}
      options={moneyKitModeOptions}
      value={activeModeOption}
    />
  );
};

export default ModeSelector;
