import { Auth0Provider as Provider } from "@auth0/auth0-react";
import type { AppState } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import {
  auth0AudienceAuthGateway,
  auth0ClientID,
  auth0Domain,
} from "../config";

const auth0ScopeEmail = "email";
const auth0ScopeProfile = "profile";

const scopeReadPartners = "read:partners";
const scopeWritePartners = "write:partners";
const scopeReadInstitutions = "read:institutions";
const scopeReadLinks = "read:links";
const scopeReadProfiles = "read:profiles";
const scopeReadReports = "read:reports";

const scopeWriteInstitutions = "write:institutions";

const requiredScopes = [
  auth0ScopeEmail,
  auth0ScopeProfile,

  scopeReadPartners,
  scopeWritePartners,
  scopeReadInstitutions,
  scopeReadLinks,
  scopeReadProfiles,
  scopeReadReports,
  scopeWriteInstitutions,
].join(" ");

type Props = {
  children: ReactNode;
};

const Auth0Provider = ({ children }: Props) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Provider
      authorizationParams={{
        audience: auth0AudienceAuthGateway,
        redirect_uri: window.location.origin,
        scope: requiredScopes,
      }}
      clientId={auth0ClientID}
      domain={auth0Domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Provider>
  );
};

export default Auth0Provider;
