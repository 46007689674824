import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 14}
    height={props.height ?? 11}
    viewBox="0 0 14 11"
    fill="none"
    {...props}
  >
    <path
      fill="#8A8A8E"
      fillRule="evenodd"
      d="M13.707.307c.39.409.39 1.072 0 1.481l-8.5 8.905a.968.968 0 0 1-1.414 0l-3.5-3.667a1.083 1.083 0 0 1 0-1.481.968.968 0 0 1 1.414 0L4.5 8.47 12.293.307a.968.968 0 0 1 1.414 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
