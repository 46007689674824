"use client";

import {
  CalendarDate,
  ZonedDateTime,
  parseAbsolute,
  toZoned,
  today,
} from "@internationalized/date";
import { useState } from "react";
import {
  Button,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DateRangePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
  RangeCalendar,
  RangeCalendarContext,
} from "react-aria-components";

import { classNames } from "@mk/util-css";

const calendarMonthSwitcherButtonStyles = `flex items-center justify-center transition rounded-lg w-9 h-9 bg-separator-tertiary active:scale-95 focus:bg-fill-secondary outline-none focus-visible:ring-2 focus-visible:ring-accent`;

export const now = today("America/New_York");
export const lastWeek = now.subtract({ days: 7 });
export const lastTwoWeeks = now.subtract({ days: 14 });
export const thirtyDaysAgo = now.subtract({ days: 30 });
export const threeMonthsAgo = now.subtract({ months: 3 });
export const fiveThousandDaysAgo = now.subtract({ days: 5000 });

export const convertToCalendarDate = (date: string) => {
  const dateString = date.replace(/-/g, "");
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6));
  const day = parseInt(dateString.substring(6, 8));
  return new CalendarDate(year, month, day);
};

export const applyCalendarRangeFilter = (
  startDate: CalendarDate,
  endDate: CalendarDate,
  pathname: string = window.location.pathname
) => {
  const params = new URLSearchParams(window.location.search);
  /**
   * 1. Convert simple date from calendar to a datetime in the US Eastern timezone.
   * 2. Manipulate the time of day on each end of the range to be the start/end of that day.
   * 3. toAbsoluteString() converts the times into UTC before being sent to the server.
   */
  const startAsLocalTime = startOfDay(toZoned(startDate, "America/New_York"));
  const endAsLocalTime = endOfDay(toZoned(endDate, "America/New_York"));
  params.set("start_at", startAsLocalTime.toAbsoluteString());
  params.set("end_at", endAsLocalTime.toAbsoluteString());
  const path = pathname + "?" + params.toString();
  window.history.pushState({}, "", path);
};

export function DateRangeDropdownPicker({
  afterChange,
}: {
  afterChange?: () => void;
}) {
  const existingParams = new URLSearchParams(window.location.search);
  const queryStart = existingParams.get("start_at");
  const queryEnd = existingParams.get("end_at");
  const backupDate = new Date().toISOString();
  const convertedStart = parseAbsolute(
    queryStart ?? backupDate,
    "America/New_York"
  );
  const convertedEnd = parseAbsolute(
    queryEnd ?? backupDate,
    "America/New_York"
  );
  const defaultRange =
    convertedStart && convertedEnd
      ? {
          start: new CalendarDate(
            convertedStart.year,
            convertedStart.month,
            convertedStart.day
          ),
          end: new CalendarDate(
            convertedEnd.year,
            convertedEnd.month,
            convertedEnd.day
          ),
        }
      : {
          start: new CalendarDate(now.year, now.month, now.day),
          end: new CalendarDate(now.year, now.month, now.day),
        };
  const [rangeValue, setRangeValue] = useState(defaultRange ?? null);

  return (
    <RangeCalendarContext.Provider value={{ value: rangeValue }}>
      <DateRangePicker
        aria-label="Select date range"
        defaultValue={defaultRange}
        value={rangeValue}
        className="relative flex justify-center flex-none overflow-hidden no-tap-highlight"
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            afterChange?.();
          }
        }}
        onChange={({ start, end }) => {
          applyCalendarRangeFilter(start, end);
          setRangeValue({ start, end });
        }}
        onFocusChange={(focused) => {
          if (!focused) {
            afterChange?.();
          }
        }}
      >
        <Group
          className={classNames(
            "flex-none relative flex items-center justify-center gap-2 transition outline-none h-10 text-sm font-semibold focus:ring-2 focus:ring-accent no-tap-highlight bg-fill-tertiary rounded-[10px] hover:bg-separator-primary pr-3 pl-4"
          )}
        >
          {rangeValue && (
            <div className="flex items-center flex-none gap-1 h-11">
              <DateInput slot="start" className="flex">
                {(segment) => (
                  <DateSegment
                    segment={segment}
                    className="px-px rounded-md outline-none focus:text-white focus:bg-accent caret-transparent tabular-nums"
                  />
                )}
              </DateInput>
              <span className="text-xl font-bold" aria-hidden="true">
                -
              </span>
              <DateInput slot="end" className="flex">
                {(segment) => (
                  <DateSegment
                    segment={segment}
                    className="px-px rounded-md outline-none focus:text-foreground-primary focus:bg-accent caret-transparent tabular-nums"
                  />
                )}
              </DateInput>
            </div>
          )}
          <Button
            aria-label="Select date range"
            className={classNames(
              "flex-none flex items-center justify-center h-11 outline-none gap-2 hover:text-foreground-primary focus-visible:text-accent select-none no-tap-highlight relative group"
            )}
          >
            {!rangeValue && <span className="relative flex-none">7 days</span>}
            <div className="flex-none">
              <DropdownIcon />
            </div>
          </Button>
        </Group>
        <Popover placement="bottom" offset={6}>
          <Dialog className="p-4 overflow-hidden shadow-xl backdrop-blur-2xl bg-content-primary rounded-3xl ring-1 ring-separator-tertiary">
            <RangeCalendar isDateUnavailable={(date) => date.compare(now) > 0}>
              <div className="flex items-center justify-between text-foreground-primary">
                <Button
                  slot="previous"
                  className={calendarMonthSwitcherButtonStyles}
                  aria-label="Previous month"
                >
                  <span className="relative rotate-90">
                    <DropdownIcon />
                  </span>
                </Button>
                <Heading className="text-base font-bold text-foreground-primary" />
                <Button
                  slot="next"
                  className={calendarMonthSwitcherButtonStyles}
                  aria-label="Next month"
                >
                  <span className="relative -rotate-90">
                    <DropdownIcon />
                  </span>
                </Button>
              </div>
              <CalendarGrid className="mt-4 min-h-[298px]">
                <CalendarGridHeader>
                  {(day) => (
                    <CalendarHeaderCell className="pt-3 pb-3 text-xs font-semibold opacity-50 text-foreground-secondary">
                      {day}
                    </CalendarHeaderCell>
                  )}
                </CalendarGridHeader>
                <CalendarGridBody>
                  {(date) => (
                    <CalendarCell
                      date={date}
                      aria-label={date.day}
                      className={classNames(
                        "flex items-center justify-center w-10 h-10 text-sm font-bold border-none outside-visible-range:opacity-50 group outside-month:text-foreground-secondary outline-none border-spacing-0 outside-month:cursor-default",
                        "selected:bg-separator-tertiary [td:first-child_&]:rounded-s-full selection-start:rounded-s-full [td:last-child_&]:rounded-e-full selection-end:rounded-e-full",
                        "selection-start:before:bg-accent selection-start:before:rounded-full selection-start:before:w-10 selection-start:before:h-10 selection-start:before:absolute",
                        "selection-end:before:bg-accent selection-end:before:rounded-full selection-end:before:w-10 selection-end:before:h-10 selection-end:before:absolute selection-start:text-white selection-end:text-white",
                        "outside-visible-range:outside-month:selected:bg-black",
                        "unavailable:opacity-50 unavailable:text-foreground-tertiary unavailable:cursor-default text-foreground-secondary",
                        date.compare(now) === 0 &&
                          "before:w-10 before:h-10 before:ring-2 before:ring-inset before:ring-fill-secondary before:absolute before:rounded-full text-foreground-primary selection-start:before:ring-0 selection-end:before:ring-0"
                      )}
                    >
                      <span className="relative z-20">{date.day}</span>
                    </CalendarCell>
                  )}
                </CalendarGridBody>
              </CalendarGrid>
            </RangeCalendar>
          </Dialog>
        </Popover>
      </DateRangePicker>
    </RangeCalendarContext.Provider>
  );
}

export function startOfDay(dateTime: ZonedDateTime): ZonedDateTime {
  return dateTime.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

export function endOfDay(dateTime: ZonedDateTime): ZonedDateTime {
  return dateTime.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });
}

const DropdownIcon = () => (
  <svg height="20" width="20" viewBox="0 0 20 20" fill="currentColor">
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
  </svg>
);
