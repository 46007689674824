import {
  BrowserTracing,
  Replay,
  init as initSentry,
  reactRouterV6Instrumentation,
} from "@sentry/react";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import App from "./app";
import "./styles/index.css";
import { moneykitAPIHost, sentryDSN, sentryEnv } from "./config";

initSentry({
  dsn: sentryDSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Replay(),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0,

  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", moneykitAPIHost],

  environment: sentryEnv,
});

const container = document.getElementById("root");

if (container === null) {
  throw new Error(
    "#root element not found in HTML; unable to render React app."
  );
}

const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
